import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "How to cut out small print runs",
  "author": "Stefan Huber",
  "date": "2018-12-03",
  "layout": "post",
  "draft": false,
  "path": "/posts/cut-out-small-print-runs/",
  "tags": ["TagOne", "TagTwo"],
  "description": "After printing a small run I usually cut the paper by hand. But what's the best technic?"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "finding-the-right-crop"
    }}>{`Finding the right crop`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f87e3a68d62f598f9a8c5b1d185a6255/3ed96/IMG_8361_sh.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "150%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgUD/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAIDAf/aAAwDAQACEAMQAAABZTeCuYQZPWot0GSGQX//xAAbEAADAAMBAQAAAAAAAAAAAAAAAQIDEjERIf/aAAgBAQABBQJSh46q8bbU8lfcZPNJINmj3ZKUj//EABcRAQEBAQAAAAAAAAAAAAAAAAECEBH/2gAIAQMBAT8BJEwrmf/EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/ASn/xAAdEAACAQQDAAAAAAAAAAAAAAAAAREDECFxIjFB/9oACAEBAAY/AjjUhaHL9tlD3aR7OyGYP//EABwQAQADAQADAQAAAAAAAAAAAAEAESExEGFxsf/aAAgBAQABPyFIYRvcjhbJfUpScIo0PWTn7zhEtIHdn6vGLVLHsGxU/9oADAMBAAIAAwAAABD0wH3/xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAQ/9oACAEDAQE/ECC8QSab/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxAyTsRb/8QAHRABAQADAAIDAAAAAAAAAAAAAREAITFBUWFx8P/aAAgBAQABPxBidwdzpOpkPVy0q6fiZpx2GHC7qAefvNf3cxHWaMCRhpBDd7hhEixhICmJaFA+ciBC2Vz/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Cropping",
            "title": "Cropping",
            "src": "/static/f87e3a68d62f598f9a8c5b1d185a6255/d9c39/IMG_8361_sh.jpg",
            "srcSet": ["/static/f87e3a68d62f598f9a8c5b1d185a6255/20e5d/IMG_8361_sh.jpg 450w", "/static/f87e3a68d62f598f9a8c5b1d185a6255/8e1fc/IMG_8361_sh.jpg 900w", "/static/f87e3a68d62f598f9a8c5b1d185a6255/d9c39/IMG_8361_sh.jpg 1800w", "/static/f87e3a68d62f598f9a8c5b1d185a6255/3ed96/IMG_8361_sh.jpg 2621w"],
            "sizes": "(max-width: 1800px) 100vw, 1800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`To figure out the final crop I usually put masks – made out of gray cardboard – on the print. I consider the final proportion of the format and try to have some rational numbers (2:3, 3:5, ...).`}</p>
    <h2 {...{
      "id": "making-a-mask"
    }}>{`Making a Mask`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c6513120714576cd62e837f876234f11/51415/IMG_8933_sh.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAF2pMxdBs7/AP/EABgQAAMBAQAAAAAAAAAAAAAAAAECIQAT/9oACAEBAAEFAkYBuibomIrJAZ//xAAXEQEAAwAAAAAAAAAAAAAAAAAAAhES/9oACAEDAQE/AbZk/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAIT/9oACAECAQE/AWsv/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAEQETHh/9oACAEBAAY/AnZpyLTj/8QAGhAAAwADAQAAAAAAAAAAAAAAAAEREDFRYf/aAAgBAQABPyGopcSno6jcCaLI4f/aAAwDAQACAAMAAAAQCC//xAAXEQEBAQEAAAAAAAAAAAAAAAABABFh/9oACAEDAQE/EBBlwv/EABURAQEAAAAAAAAAAAAAAAAAAABh/9oACAECAQE/EFX/xAAaEAEAAgMBAAAAAAAAAAAAAAABABEhMVFB/9oACAEBAAE/EKb1kX7uDavXBiq0t1i5vXJQ81lPTjBeyS9z/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example",
            "title": "Example",
            "src": "/static/c6513120714576cd62e837f876234f11/d9c39/IMG_8933_sh.jpg",
            "srcSet": ["/static/c6513120714576cd62e837f876234f11/20e5d/IMG_8933_sh.jpg 450w", "/static/c6513120714576cd62e837f876234f11/8e1fc/IMG_8933_sh.jpg 900w", "/static/c6513120714576cd62e837f876234f11/d9c39/IMG_8933_sh.jpg 1800w", "/static/c6513120714576cd62e837f876234f11/51415/IMG_8933_sh.jpg 2179w"],
            "sizes": "(max-width: 1800px) 100vw, 1800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`After I found the right spot I make a mask with tracing paper. On the tracing paper I make some markings of the print so I can easily place it on the next print. I take care its correct 90° angeled so the final product will not end up as rhomubs. In the corners I place a transparent tape to punch with the stitching awl through and mark therefore my final format on the print. Now I'm able to crop it with a cutter.`}</p>
    <h3 {...{
      "id": "thanks"
    }}>{`Thanks`}</h3>
    <p>{`Thanks to `}<a parentName="p" {...{
        "href": "https://www.zhdk.ch/person/10417"
      }}>{`Rudolf Barmettler`}</a>{` for showing me this technic`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      